import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        component: () => import('../pages/profile/LoginComponent'),
        name: 'Login',
    },
    {
        path: '/register/:tg?',
        component: () => import('../pages/profile/RegisterComponent'),
        name: 'Register',
    },
    {
        path: '/',
        component: () => import('../pages/dashboard/DashboardComponent'),
        name: 'Dashboard',
    },
    {
        path: '/tickets/create',
        component: () => import('../pages/dashboard/tickets/TicketsCreate'),
        name: 'TicketCreate',
    },
    {
        path: '/tickets',
        component: () => import('../pages/dashboard/tickets/TicketsList'),
        name: 'Tickets',
    },
    {
        path: '/tickets/:id',
        component: () => import('../pages/dashboard/tickets/TicketsItem'),
        name: 'Ticket',
    },
    {
        path: '/articles',
        component: () => import('../pages/dashboard/articles/ArticlesList.vue'),
        name: 'Articles',
    },
    {
        path: '/userDashboard',
        component: () => import('../pages/profile/UserDashboard.vue'),
        name: 'UserDashboard'
    },
    {
        path: '/articles/:id',
        component: () => import('../pages/dashboard/articles/ArticlePage.vue'),
        name: 'ArticlePage',
    },
    {
        path: '/accounter-kitchen-salary',
        component: () => import('../pages/dashboard/AccounterKitchenSalary'),
        name: 'AccounterKitchenSalary'
    },
    {
        path: '/stop-sales',
        component: () => import('../pages/admin/stops/StopList.vue'),
        name: 'StopList'
    },
    {
        path: '/local-premias',
        component: () => import('../pages/admin/salary/AdminLocalPremias.vue'),
        name: 'LocalPremia'
    },
    {
        path: '/local-premia-types',
        component: () => import('../pages/admin/salary/LocalPremiaTypes.vue'),
        name: 'LocalPremiaTypes'
    },
    {
        path: '/polls',
        component: () => import('../pages/admin/polls/PollList.vue'),
        name: 'PollsList'
    },
    {
        path: '/polls/nps-stats',
        component: () => import('../pages/admin/polls/NPSPollStatistics.vue'),
        name: 'NPSStatistics'
    },
    {
        path: '/polls/stats/:id',
        component: () => import('../pages/admin/polls/PollStatistics.vue'),
        name: 'PollsItem'
    },
    {
        path: '/polls/edit/:id',
        component: () => import('../pages/admin/polls/PollItemEdit.vue'),
        name: 'PollItemEdit'
    },
    {
        path: '/polls/:id',
        component: () => import('../pages/admin/polls/PollItem.vue'),
        name: 'PollsItem'
    },
    {
        path: '/kln-stats',
        name: 'KlnStatistics',
        component: () => import('../pages/admin/kln_stat/KlnStatistics.vue'),
    },
    {
        path: '/kln-block-stats',
        name: 'KlnBlockStatistics',
        component: () => import('../pages/admin/kln_stat/KlnBlockStatistics.vue'),
    },
    {
        path: '/transaction-tree/:pizzeria_id?',
        name: 'TransactionTree',
        component: () => import('../components/TransactionTree.vue'),
    },
    {
        path: '/admin',
        component: () => import('../pages/admin/AdminComponent'),
        name: 'Admin',
        children: [
            {
                path: 'dashboard',
                component: () => import('../pages/admin/AdminDashboard'),
                name: 'AdminDashboard',
            },
            {
                path: 'courierpayments/:pizzeria?/:date?',
                component: () => import('../pages/admin/salary/AdminSalary.vue'),
                name: 'AdminCourierPayments',
            },
            {
                path: 'kitchensalary/:pizzeria?/:date?',
                component: () => import('../pages/admin/salary/AdminKitchenSalary.vue'),
                name: 'AdminKitchenSalary',
            },
            {
                path: 'kitchensalarystats/:pizzeria?/:date?',
                component: () => import('../pages/admin/salary/AdminKitchenSalaryStats.vue'),
                name: 'AdminKitchenSalaryStats',
            },
            {
                path: 'salaryDecember',
                component: () => import('../pages/admin/salary/SalaryDecember.vue'),
                name: 'SalaryDecember',
            },
            {
                path: 'shift_premias',
                component: () => import('../pages/admin/salary/AdminShiftPremias.vue'),
                name: 'AdminShiftPremias',
            },
            {
                path: 'premias',
                component: () => import('../pages/admin/salary/AdminPremias.vue'),
                name: 'AdminPremia',
            },
            {
                path: 'shifts/:pizzeria/:date',
                component: () => import('../pages/admin/salary/AdminShifts.vue'),
                name: 'AdminShifts',
            },
            {
                path: 'schedules/:pizzeria/:date',
                component: () => import('../pages/admin/salary/AdminSchedules.vue'),
                name: 'AdminShifts',
            },
            {
                path: 'users',
                component: () => import('../pages/admin/users/UsersList'),
                name: 'Users',
            },
            {
                path: 'users/:id',
                component: () => import('../pages/admin/users/UsersItem'),
                name: 'User',
            },
            {
                path: 'pizzerias',
                component: () => import('../pages/admin/pizzerias/PizzeriaList.vue'),
                name: 'Pizzerias'
            },
            {
                path: 'pizzerias/:id',
                component: () => import('../pages/admin/pizzerias/PizzeriaItem.vue'),
                name: 'Pizzeria'
            },
            {
                path: 'categories',
                component: () => import('../pages/admin/categories/CategoryList'),
                name: 'Category',
            },
            {
                path: 'cameras',
                component: () => import('../pages/admin/Cameras.vue'),
                name: 'Camera',
            },
            {
                path: 'categories/:id',
                component: () => import('../pages/admin/categories/CategoryItem'),
                name: 'Category',
            },
            {
                path: 'ofd_shifts',
                component: () => import('../pages/admin/ofd_shifts/OfdShifts.vue'),
                name: 'OfdShifts',
            },
            {
                path: 'cash_reports',
                component: () => import('../pages/admin/cash_reports/CashReports.vue'),
                name: 'CashReports',
            },
            {
                path: 'summary',
                component: () => import('../pages/admin/cash_reports/Summary.vue'),
                name: 'Summary',
            },
            {
                path: 'summary2',
                component: () => import('../pages/admin/cash_reports/Summary2.vue'),
                name: 'Summary2',
            },
            {
                path: 'articles/:id',
                component: () => import('../pages/admin/articles/ArticleCard.vue'),
                name: 'Article',
            },
            {
                path: 'contractors',
                component: () => import('@/pages/admin/contractors/ContractorList.vue'),
                name: 'Contractors',
            },
            {
                path: 'contractors/:id',
                component: () => import('@/pages/admin/contractors/ContractorCard.vue'),
                name: 'Contractor',
            },
            {
                path: 'roles',
                component: () => import('../pages/admin/roles/RoleList.vue'),
                name: 'Roles',
            },
            {
                path: 'saved-filters',
                component: () => import('../pages/admin/saved-filters/SavedFiltersList.vue'),
                name: 'SavedFilters',
            },
            {
                path: 'metrics',
                component: () => import('../pages/admin/metrics/MetricList'),
                name: 'MetricList',
            },
            {
                path: 'metric-types',
                component: () => import('../pages/admin/metrics/MetricTypes'),
                name: 'MetricTypes',
            },
            {
                path: 'metrics/create',
                component: () => import('../pages/admin/metrics/MetricCreate'),
                name: 'MetricCreate',
            },

            // Dodois
            {
                path: 'dodois',
                component: () => import('../pages/admin/dodois/DodoisPage'),
                name: 'AdminDodois',
            },
            {
                path: 'dodois-delivery-statistics',
                name: 'AdminDodoisDeliveryStatistics',
                component: () => import('../pages/admin/dodois/DodoisDeliveryStatisticsPage')
            },
            {
                path: 'dodois-shifts',
                name: 'AdminDodoisShifts',
                component: () => import('../pages/admin/dodois/DodoisShiftsPage')
            },
            {
                path: 'dodois-shift-premias',
                name: 'AdminDodoiShiftPremias',
                component: () => import('../pages/admin/dodois/DodoisShiftPremiasPage')
            },
            {
                path: 'dodois-staff-schedules',
                name: 'AdminDodoisStaffSchedules',
                component: () => import('../pages/admin/dodois/DodoisStaffSchedulesPage')
            },
            {
                path: 'dodois-users/:id',
                name: 'AdminDodoisUserCard',
                component: () => import('../pages/admin/dodois/DodoisUsers/Card')
            },
            {
                path: 'dodois-users',
                name: 'AdminDodoisUsers',
                component: () => import('../pages/admin/dodois/DodoisUsersPage')
            },
            {
                path: 'dodois-accounting-cancelled-sales',
                name: 'AdminDodoisAccountingCancelledSales',
                component: () => import('../pages/admin/dodois/DodoisAccountingCancelledSalesPage')
            },
            {
                path: 'dodois-accounting-incoming-stock-items',
                name: 'AdminDodoisAccountingIncomingStockItems',
                component: () => import('../pages/admin/dodois/DodoisAccountingIncomingStockItemsPage')
            },
            {
                path: 'dodois-delivery-couriers-orders',
                name: 'AdminDodoisDeliveryCouriersOrders',
                component: () => import('../pages/admin/dodois/DodoisDeliveryCouriersOrdersPage')
            },
            {
                path: 'dodois-delivery-vouchers',
                name: 'AdminDodoisDeliveryVouchers',
                component: () => import('../pages/admin/dodois/DodoisDeliveryVouchersPage')
            },
            {
                path: 'dodois-stop-sales',
                name: 'AdminDodoisStopSales',
                component: () => import('../pages/admin/dodois/DodoisStopSalesPage')
            },
            {
                path: 'dodois-category-history',
                name: 'AdminDodoisCategoryHistory',
                component: () => import('../pages/admin/dodois/CategoryHistory')
            },

            {
                path: 'notifiers',
                name: 'AdminNotifiersList',
                component: () => import('../pages/admin/notifiers/List'),
            },
            {
                path: 'notifiers/:id',
                name: 'AdminNotifierCard',
                component: () => import('../pages/admin/notifiers/Card'),
            },
            {
                path: 'costs',
                name: 'AdminCosts',
                component: () => import('../pages/admin/costs/Costs'),
            },
            {
                path: 'cost-factors',
                name: 'AdminCostFactors',
                component: () => import('../pages/admin/costs/CostFactors'),
            },

            // СБЕР
            {
                path: 'sber',
                name: 'AdminSber',
                component: () => import('../pages/admin/sber/Index'),
            },
            {
                path: 'sber-summaries',
                name: 'AdminSberSummaryList',
                component: () => import('../pages/admin/sber/SummaryList'),
            },
            {
                path: 'sber-transactions/:id',
                name: 'AdminSberTransactionCard',
                component: () => import('../pages/admin/sber/TransactionCard'),
            },
            {
                path: 'sber-transactions',
                name: 'AdminSberTransactionList',
                component: () => import('../pages/admin/sber/TransactionList'),
            },
            // Chats
            {
                path: 'chats',
                name: 'AdminChatList',
                component: () => import('../pages/admin/chats/ChatList.vue'),
            },
            {
                path: 'costTableDecember',
                name: 'CostTableDecember',
                component: () => import('../pages/admin/costs/CostTableDecember.vue'),
            },
            {
                path: 'commands',
                name: 'CommandsList',
                component: () => import('../pages/admin/commands/CommandsList.vue'),

            },
            {
                path: 'activeNoShifts',
                component: () => import('@/pages/admin/commands/activeNoShifts/ActiveNoShiftsList.vue'),
                name: 'ActiveNoShifts',
            },
            {
                path: 'latecomersNotifier',
                component: () => import('@/pages/admin/commands/LatecomersNotifier.vue'),
                name: 'LatecomersNotifier'
            },
            {
                path: 'latecomersInterface',
                component: () => import('@/pages/admin/commands/LatecomersInterface.vue'),
                name: 'Latecomers'
            },
            {
                path: 'klnCalcValues',
                component: () => import('@/pages/admin/klnCalculation/KlnCalcValuesList.vue'),
                name: 'klnCalcValues'
            },
            {
                path: 'klnCalcCategoryCreate',
                component: () => import('@/pages/admin/klnCalculation/KlnCategoriesCreation.vue'),
                name: 'KlnCategoriesCreation'
            },
            {
                path: 'local-operations',
                component: () => import('@/pages/admin/LocalOperations/LocalOperationsList.vue'),
                name: 'LocalOperationsList'
            },
            {
                path: 'ticket-statistic',
                component: () => import('@/pages/admin/ticketStatistic/TicketStatistic.vue'),
                name: 'TicketStatistic'
            },
            {
                path: 'ticket-rating-statistic',
                component: () => import('@/pages/admin/ticketStatistic/TicketRatingStatistic.vue'),
                name: 'TicketRatingStatistic'
            },
            {
                path: 'dodo-gpt',
                component: () => import('@/pages/admin/Gpt.vue'),
                name: 'dodo-gpt'
            },
            {
                path: 'test-page',
                component: () => import('@/pages/admin/testpage.vue'),
                name: 'test-page'
            },
            {
                path: 'operation-costs',
                component: () => import('@/pages/admin/OperationCost/OperationCostTable.vue'),
                name: 'operation-costs'
            },
            {
                path: 'ivl-input',
                component: () => import('@/pages/admin/ivl/IvlFileInput.vue'),
                name: 'IvlFileInput'
            },
            {
                path: 'workhands-login',
                component: () => import('@/pages/admin/WorkhandsLogin.vue'),
                name: 'WorkhandsLogin'
            },
            {
                path: 'locations',
                component: () => import('@/pages/admin/locations/LocationViewer.vue'),
                name: 'LocationsViewer'
            },
            {
                path: 'klnSettings',
                component: () => import('@/pages/admin/kln_stat/kln/KlnSettingsList.vue'),
                name: 'KlnSettings',
            },
            {
                path: 'emails',
                component: () => import('@/pages/admin/EmailPage.vue'),
                name: 'Emails',
            }
        ],
    },
    {
        path: '/kln',
        name: 'AdminKLN',
        component: () => import('../pages/dashboard/user-metrics/Kln'),
    },
    {
        path: '/klnCalculation',
        component: () => import('@/pages/admin/klnCalculation/KlnCalculation.vue'),
        name: 'klnCalculation'
    },
    {
        path: '/managers-kln',
        name: 'ManagersKLN',
        component: () => import('../pages/dashboard/user-metrics/ManagersKLN.vue'),
    },
    {
        path: '/klnHistory',
        name: 'KLNHistory',
        component: () => import('../pages/dashboard/user-metrics/KlnHistory.vue'),
    },
    {
        path: '/api/dodois',
        component: () => import('../pages/Api.vue'),
        name: 'Api',
    },
    {
        path: '/cash_stat',
        component: () => import('../pages/admin/cash_reports/CashStat.vue'),
        name: 'CashStat',
    },
    {
        path: '/budgets/operations/:pizzeria?',
        component: () => import('../pages/admin/budgets/operationsComparison.vue'),
        name: 'operationsComparison',
    },
    {
        path: '/create_cash_report',
        component: () => import('../pages/admin/cash_reports/CashReportsCreate.vue'),
        name: 'CashReportsCreate',
    },
    {
        path: '/notifications',
        component: () => import('../pages/admin/users/NotificationsList'),
        name: 'Notifications',
    },
    {
        path: '/promocodesCreate',
        component: () => import('@/pages/admin/promocodes/PromocodeCreate.vue'),
        name: 'PromocodeCreate'
    },
    {
        path: '/promocodesList',
        component: () => import('@/pages/admin/promocodes/PromocodeList.vue'),
        name: 'PromocodeList'
    },
    {
        path: '*',
        redirect: '/'
    },
]

export default new VueRouter({
    mode: 'history',
    routes
})
